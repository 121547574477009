@import url('https://fonts.googleapis.com/css2?family=Heebo:wght@100..900&display=swap');

:root {
  background-color: #FFFDF8;
  /* padding: 64px; */
  justify-content: center;
  padding: 0px;
  margin: 0px;
  flex: 1;

}

@media (max-width: 768px) {
  :root {
    justify-content: center;
    padding: 0px;
    margin: 0px;
    flex: 1;

    /* padding: 16px; */
  }
}

body {
  font-family: 'Heebo', sans-serif;
  background: #FFFDF8;
  display: flex;
  flex-direction: column;
  margin: 0px;
  height: 100vh;
  flex: 1;
  display: flex;
}

@layer utilities {
  .text-balance {
    text-wrap: balance;
  }
}