.container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px;
    justify-content: center;
    gap: 32px;
    place-items: center;
}

.item {
    display: flex;
    flex-direction: column;
    max-width: 500px;
    justify-content: center;
    gap: 16px;
    flex: 1;
    width: 100%;
}

.centered{
    align-items: center;
}

.contactButton{
    background-color: #252525;
    display: flex;
    border-radius: 30px;
}

.contactButton p {
    text-align: center;
    color: white;
    font-size: 1.5rem;
    text-align: center;
    padding: 10px;
    margin: 0;
    flex: 1;
    min-width: 330px;
}
.center{
    align-items: center;
}


.a {
    order: 1;
}

.b{
    order: 2;
}

.c{
    order: 3;
}

.d{
    order: 4;
}  

@media (max-width: 768px) {
    .container {
        grid-template-columns: 1fr;
        gap: 16px;
        margin-top: 35px;
    }
    .a {
        order: 1;
    }

    .b{
        order: 2;
    }

    .c{
        order: 4;
    }

    .d{
        order: 3;
    }   

    .contactButton a{
        color: white;
        font-size: 1rem;
        text-align: center;
        padding: 10px;
        margin: 0;
        flex: 1;
    }
}