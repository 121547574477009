/* styles/Navbar.module.css */
.navbar {
  position: sticky;
  top: 0;
  z-index: 1000;
  padding: 10px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #FFFDF8;
  height: 45px;
}

.navbar p {
  color: #000;
  text-decoration: none;
}

.navLinks {
  list-style: none;
  display: flex;
  align-items: center;
  gap: 10px;
}

.navLinks li a{
  text-decoration: none;
}

.navLinks li:last-child {
  margin-right: 0;
}

.logo {
  font-size: 1.5rem;
  font-weight: bold;
}

.mobileMenuIcon {
  display: none; /* Hide by default */ 
  cursor: pointer;
}

@media (max-width: 768px) {
  .navLinks {
    display: none; /* Hide nav links on small screens */
    flex-direction: column;
    background-color: #FFFDF8;
    position: absolute;
    top: 45px;
    left: 0;
    width: 100%;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    align-items: flex-start;
    padding-left: 0px;
  }

  .navLinks:hover{
    background-color: 'white';
  }

  .navLinks li {
    margin-right: 0;
    margin-bottom: 10px;
    margin-left: 15px;
  }

  .mobileMenuIcon {
    display: block; /* Show mobile menu icon on small screens */
  }
  .open {
    display: flex; /* Show nav links when mobile menu is open */
  }
}



.contact{
  background-color: #FF856A;
  width: 170px;
  text-align: center; /* Center text horizontally */
  border-radius: 15px;
  align-self: flex-end;
}

.contact p {
  color: white;
  text-align: center;

}

.navItem{
  display: flex;
  border-radius: 15px;
  padding: 0px 10px;
  align-items: center;
}

.navItem p {
  margin: 10px 0px;
}
.navItem:hover{
  background-color: #FF856A;
}

.navItem:hover p {
  color: white;

}

.navItem img{
  margin-right: 10px;
}