.container{
    border-radius: 8px;
}

.formContainer{
    background-color: white;
    border-radius: 8px;
    padding: 32px;
    gap: 18px;
    display: flex;
    flex-direction: column;
}

.bold{
    font-weight: bold;
}

.header{
    font-size: x-large;
}

.input{
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 8px;
}

.column{
    display: flex;
    flex-direction: column;
}