 
.contentContainer{
  padding: 64px;
  display: flex;
  justify-content: center;
  flex: 1;
}

.footer{
  display: flex;
  justify-content: space-between;
  padding: 16px;
}


.icons{
  margin: 0px 10px;
  height: 18px;
  width: 19px;
}

@media (max-width: 768px) {
  .contentContainer {
      padding: 16px;
  }
}